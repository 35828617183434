<!-- 入驻状态 -->
<template>
        <div>
        <div class="m-r-30 float-right">
            <div><!-- {{enterInfo.enterpriseName}}  -->
                <el-dropdown @command="handleCommand">
                    <el-button type="primary" size="mini">
                        切换企业<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(v,i) of enterpriseByUserList" :command="i" :key="i">
                            <div :class="{'select':v.id==enterprise.id}">{{v.enterpriseName}}</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="text-left">
                <!-- <el-rate v-model="myenterInfo.enterpriseStar"></el-rate> -->
            </div>
        </div>
            
    <div class="msgResult">
        <div>
            <template v-if="msgType===0">
                <i class="el-icon-success" style="font-size: 120px;color: #278eff;"></i>
            </template>
            <template v-if="msgType===1">
                <i class="el-icon-warning" style="font-size: 120px;color: #ffb819;"></i>
            </template>
            <template v-if="msgType===2">
                <i class="el-icon-error" style="font-size: 120px;color: #ff5c52;"></i>
            </template>
            <template v-if="msgType===3">
                <i class="el-icon-question" style="font-size: 120px;color: #2a2c33;"></i>
            </template>
            <template v-if="msgType===4">
                <i class="el-icon-loading" style="font-size: 120px;color: #2a2c33;"></i>
            </template>
        </div>
        <div>
            <h2>{{title}}</h2>
            <div>{{desc}}</div>
        </div>
        <div style="padding: 70px 0 0 0">
            <template v-for="item in buttons">
                <el-button :type="item.type" @click="item.action">{{item.name}}</el-button>
            </template>
        </div>
        </div>

    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { userAuth } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"
export default {
    name: "msgResult",
    props: {
        msgType: {
            type: Number,
            default: 2,
            required: true
        },
        buttons: {
            type: Array,
            default: [{
                name: '主要按钮',
                type: 'primary',
                action: null
            }, {
                name: '次要按钮',
                type: 'default',
                action: null
            }],
            required: true
        },
        title: {
            type: String,
            default: '主要提示文字',
            required: true
        },
        desc: {
            type: String,
            default: '描述',
            required: true
        }
    },

    data(){
        return{
            enterInfo:null,
            enterpriseByUserList:[],
            enterprise:{}
        }
    },
    mounted() {
        const me = this;
        let enterprise=localGet('enterprise')

            // 查询客户端用户的所有企业
        userAuth.getEnterpriseByUser({}).then(res=>{
           console.log(res)
           if(res && res.length){
                if(enterprise && enterprise.id){
                    this.enterprise = enterprise
                }else{                        
                    let enterprise=res[0]
                    for(let v of res){
                        if(v.isdefault){
                            enterprise=v
                        }
                    }
                    this.enterprise=enterprise
                }
                this.enterpriseByUserList=res
                localSet('enterprise',this.enterprise)
               oucy.queryEnterApplyInfo({enterpriseId:this.enterprise.id}).then(res=>{
                   console.log(res);
                   me.enterInfo = res;
               })

           }
        })
        
    },
    methods:{
        handleCommand(i) {
            let enterprise=this.enterpriseByUserList[i]
            // console.log('this.enterprise',this.enterprise)
            // console.log('enterprise',enterprise)
            // console.log('this.enterpriseByUserList',this.enterpriseByUserList)
            // console.log('this.enterpriseByUserList[i]',this.enterpriseByUserList[i])
            if(this.enterprise.id!=enterprise.id){
                this.setUserDefaultEnterprise(enterprise.id)
                this.enterprise=enterprise
                localSet('enterprise',enterprise)
            }
        },
        setUserDefaultEnterprise(id){
            userAuth.setUserDefaultEnterprise({enterpriseId:id}).then(res=>{
                    location.reload();

            })
        },
      gotoUpgrade: function (){
          this.$router.push({
            name: 'upgradeSelect'
          })
      }
    }
    
    
}
</script>
<style scoped>
.msgResult {
    text-align: center;
    padding: 50px 25px 0 25px;
}
.select{
    color: #409EFF;
}
</style>