<template>
    <div style="text-align: left;background-color: #FFFFFF">
            <div class="pageCenter apply-container">

                <template
                        v-if="
                                buyerAuthenticationStatus!=null
                                &&
                                (
                                    buyerAuthenticationStatus === $oucy.buyerAuthenticationStatus.WAITING_FOR_APPLY
                                    ||
                                    buyerAuthenticationStatus === $oucy.buyerAuthenticationStatus.APPLY_FAIL
                                    ||
                                    buyerAuthenticationStatus === $oucy.buyerAuthenticationStatus.APPLY_SUCCESS
                                )
                ">
                    <el-form :model="identityApplyForm" :rules="identityApplyFormRules" ref="identityApplyForm"
                             label-width="90px">
                        <el-form-item label="认证身份" prop="identity">
                            <el-radio-group v-model="identityApplyForm.identity" :disabled="isApplySuccess">
                                <template v-for="item in identitys">
                                    <el-radio border :label="item.id" :value="item.id">{{item.identityName}}</el-radio>
                                </template>
                            </el-radio-group>
                        </el-form-item>
                        <p style="color: #AAAAAA;font-size: 14px;margin:50px 0 20px 90px">请上传法人身份证原件照片（确保身份证信息清晰可见）</p>
                        <el-form-item label="身份证照片" prop="idCardPhoto">
                            <el-upload
                                    accept="image/jpeg,image/gif,image/png"
                                    :before-upload="handleOnBeforeUploadAndSquare"
                                    class="avatar-uploader"
                                    action="fakeaction"
                                    :show-file-list="false"
                                    :http-request="handlePositiveImgUpload"
                                    :disabled="isApplySuccess">
                                <img v-if="identityApplyForm.positive"
                                     :src="this.$oucy.ossUrl+identityApplyForm.positive" class="avatar">
                                <div v-else class="avatar-uploader-icon avatar-uploader-icon-positive"></div>
                            </el-upload>
                            <el-upload
                                    accept="image/jpeg,image/gif,image/png"
                                    :before-upload="handleOnBeforeUploadAndSquare"
                                    class="avatar-uploader"
                                    action="fakeaction"
                                    :show-file-list="false"
                                    :http-request="handleReverseImgUpload"
                                    :disabled="isApplySuccess">
                                <img v-if="identityApplyForm.reverse" :src="this.$oucy.ossUrl+identityApplyForm.reverse"
                                     class="avatar">
                                <div v-else class="avatar-uploader-icon avatar-uploader-icon-reverse"></div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item>
                          <span style="font-size: 14px;color:#AAAAAA;padding:90px">请上传人像面</span>
                          <span style="font-size: 14px;color:#AAAAAA;padding:90px">请上传国徽面</span>
                        </el-form-item>
<!--                         <el-form-item label="签名介绍" prop="sign">
                            <el-input
                                v-model="identityApplyForm.sign"
                                type="text"
                                maxlength="20"
                                show-word-limit
                                autocomplete="off"
                                placeholder="填写个性签名"
                                :disabled="isApplySuccess"></el-input>
                        </el-form-item>
                        <el-form-item label="城市" prop="cityCode">
                            <el-cascader v-model="identityApplyForm.cityCode" style="width: 100%" :props="$oucy.cityProps"
                                         :placeholder="identityApplyForm.cityPlaceholder"
                                         :disabled="isApplySuccess">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="就职单位" prop="authenticationWorkCompany">
                            <el-input v-model="identityApplyForm.authenticationWorkCompany" autocomplete="off"
                                      placeholder="填写就职单位"
                                      :disabled="isApplySuccess">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="联系地址" prop="authenticationAddr">
                            <el-input v-model="identityApplyForm.authenticationAddr" type="text"
                                      autocomplete="off" placeholder="填写联系地址" :disabled="isApplySuccess"/>
                        </el-form-item>
                        <el-form-item label="个人简介" prop="intro">
                            <el-input
                                v-model="identityApplyForm.intro"
                                type="textarea"
                                :rows="4"
                                maxlength="80"
                                autocomplete="off"
                                placeholder="填写个人简介"
                                show-word-limit
                                :disabled="isApplySuccess"/>
                        </el-form-item> -->
                        <el-form-item prop="agreement" style="margin-left:120px;">
                          <el-checkbox v-model="agreement">同意
                            <el-link :underline="false" style="color:#0366C3;" href="" type="primary">《乐搜家买家认证服务条款》</el-link>
                          </el-checkbox>
                        </el-form-item>
                        <el-form-item v-if="!isApplySuccess" style="margin-left:90px;">
                            <el-button @click="submitIdentifyApply" type="primary" :disabled="!agreement">申请认证</el-button>
                            <el-button @click="$oucy.replace('/acc/index', null)">返回</el-button>
                        </el-form-item>
                    </el-form>
                </template>

                <template
                        v-if="
                                buyerAuthenticationStatus!=null
                                &&
                                (
                                    buyerAuthenticationStatus === $oucy.buyerAuthenticationStatus.APPLYING
                                )
                ">
                    <msgResult
                            :msg-type="4"
                            title="个人身份认证审核中"
                            desc="尊敬的用户，你提交的「个人身份认证」申请工作人员正在审核中，结果将通过短信及站内信进行通知！"
                            :buttons="applyButtons"
                    />
                </template>

            </div>
    </div>
</template>

<script>
    import oss from "@/util/oss.js"
    import oucy from "../../../util/oucyUtil";
    import msgResult from "../../../components/MsgResult"
    import {localSet, localGet, localDel} from "@/store/store"
    export default {
        name: "Identity",
        components:{
            msgResult
        },
        data() {
            const validateIdCardPhoto = (rule, value, callback) => {
                const me = this;
                if (!me.identityApplyForm.positive) {
                    callback(new Error('请上传身份证[正面]照片'));
                } else if (!me.identityApplyForm.reverse) {
                    callback(new Error('请上传身份证[反面]照片'));
                } else {
                    callback();
                }
            };
            return {
                isApplySuccess:false,
                buyerAuthenticationStatus: null,
                identitys: [],
                agreement:true,
                identityApplyForm: {
                    identity: null,
                    positive: null,
                    reverse: null,
                    sign: null,
                    cityCode: null,
                    cityPlaceholder: '请选择',
                    intro: null,
                    authenticationAddr: null,
                    authenticationWorkCompany: null
                },
                identityApplyFormRules: {
                    identity: [
                        {required: true, message: '请选择认证的身份', trigger: 'blur'},
                    ],
                    idCardPhoto: [
                        {validator: validateIdCardPhoto, trigger: 'change'},
                    ],
                    sign: [
                        {required: true, message: '请填写个性签名', trigger: 'blur'},
                    ],
                    cityCode: [
                        {required: true, message: '请选择工作所在的城市', trigger: 'blur'},
                    ],
                    intro: [
                        {required: true, message: '请填写个人简介', trigger: 'blur'},
                    ],
                    authenticationAddr: [
                        {required: true, message: '请填写个人联系地址', trigger: 'blur'},
                    ],
                    authenticationWorkCompany: [
                        {required: true, message: '请填写就职单位', trigger: 'blur'},
                    ]
                },
                cityProps: {
                    lazy: true,
                    lazyLoad(node, resolve) {
                        const {level} = node;
                        oucy.findChina(level === 0 ? null : node.value).then(res => {
                            resolve(Array.from(res).map(item => ({
                                value: item.chinaId,
                                label: item.chinaName,
                                leaf: !item.hasChildren
                            })));
                        });
                    }
                },
                applyButtons:[{
                    name:'个人中心',
                    type:'default',
                    action: function () {
                        oucy.replace('/acc');
                    }
                }],
                enterpriseId:null,
            }
        },
        mounted() {
            const me = this;
            // let enterprise=localGet('enterprise')||{}
            // this.enterpriseId=enterprise.id
            oucy.queryPersonalIdentifyStatus({}).then(res => {
                me.buyerAuthenticationStatus = res;
                return oucy.queryIdentity(false);
            }).then(res => {
                me.identitys = res;
                if(
                    me.buyerAuthenticationStatus === oucy.buyerAuthenticationStatus.APPLY_FAIL
                    ||
                    me.buyerAuthenticationStatus === oucy.buyerAuthenticationStatus.APPLY_SUCCESS
                ){
                    oucy.queryPersonalIdentifyInfo().then(res=>{
                        console.log(res);
                        me.identityApplyForm.identity = res.ltIdentityDto.id;
                        me.identityApplyForm.positive = res.authenticationIdcardPhotoPositive;
                        me.identityApplyForm.reverse = res.authenticationIdcardPhotoReverse;
                        me.identityApplyForm.sign = res.authenticationSign;
                        me.identityApplyForm.cityCode = res.chinaDto.idPath;
                        me.identityApplyForm.cityPlaceholder = res.chinaDto.namePath;
                        me.identityApplyForm.authenticationWorkCompany = res.authenticationWorkCompany;
                        me.identityApplyForm.authenticationAddr = res.authenticationAddr;
                        me.identityApplyForm.intro = res.authenticationPersonalIntro;
                        if(me.buyerAuthenticationStatus === oucy.buyerAuthenticationStatus.APPLY_SUCCESS){
                            me.isApplySuccess = true;
                        }else{
                            me.isApplySuccess = false;
                        }
                    });
                }
            });
        },
        methods: {
            handleOnBeforeUploadAndSquare: function (file) {
                const me = this;
                return new Promise((resolve, reject) => {
                    me.$oucy.checkImgFile(file).then(res => {
                        return me.$oucy.checkImgSquare(res, 0, 0);
                    }).then(res => {
                        resolve(res);
                    }).catch(err => {
                        reject(err);
                    });
                });

            },
            handlePositiveImgUpload: function (params) {
                const me = this;
                const file = params.file;
                me.$oucy.checkImgFile(file).then(res => {
                    let Oss=new oss()
                    return Oss.startUpload(file)
                    // return me.$oucy.uploadRequest(file);
                }).then(res => {
                    me.identityApplyForm.positive = res;
                });
            },
            handleReverseImgUpload: function (params) {
                const me = this;
                const file = params.file;
                me.$oucy.checkImgFile(file).then(res => {
                    let Oss=new oss()
                    return Oss.startUpload(file)
                    // return me.$oucy.uploadRequest(file);
                }).then(res => {
                    me.identityApplyForm.reverse = res;
                });
            },
            submitIdentifyApply: function () {
                const me = this;
                me.$refs["identityApplyForm"].validate((valid) => {
                    if (valid) {
                        oucy.postRequest('/client/user/buyerauthentication/applyBuyerAuthentication', {
                            authenticationIdcardPhotoPositive: me.identityApplyForm.positive,
                            authenticationIdcardPhotoReverse: me.identityApplyForm.reverse,
                            authenticationSign: me.identityApplyForm.sign,
                            chinaId: me.identityApplyForm.cityCode ?me.identityApplyForm.cityCode[me.identityApplyForm.cityCode.length - 1]:null,
                            authenticationAddr: me.identityApplyForm.authenticationAddr,
                            authenticationWorkCompany: me.identityApplyForm.authenticationWorkCompany,
                            authenticationPersonalIntro: me.identityApplyForm.intro,
                            identityId: me.identityApplyForm.identity
                        }).then(res => {
                            return oucy.queryPersonalIdentifyStatus();
                        }).then(res => {
                            me.buyerAuthenticationStatus = res;
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .apply-container {
        width: 650px;
        min-height: 600px;
        padding: 60px 0 50px 0;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 246px;
        height: 146px;
        line-height: 146px;
        text-align: center;
    }

    .avatar-uploader-icon-positive {
        background-image: url("../../../assets/idcard_positive.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .avatar-uploader-icon-reverse {
        background-image: url("../../../assets/idcard_reverse.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .avatar {
        width: 246px;
        height: 146px;
        display: block;
    }

    .avatar-uploader >>> .el-upload {
        border: 1px #d9d9d9 dashed;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        float: left;
        margin: 0 8px 8px 0;
    }

    .avatar-uploader >>> .el-upload:hover {
        border-color: #409EFF;
    }

    .el-button{
      width:143px;
    }
    /deep/ .el-form-item label:after {
            content: " ";
          }

    /deep/ .el-form-item__label {
      text-align-last: justify
    }
    /* 这里去除必选字段的*,这个符号会造成一定影响 */
    /deep/ .el-form-item.is-required .el-form-item__label:before {
      content: none !important;
    }

    /deep/ .el-radio {
        margin-bottom: 10px;
    }
</style>
